import ValidatorJS from "validator";
import { IsUrl, ValidationOptions } from "class-validator";

const baseUrlValidation: ValidatorJS.IsURLOptions = {
  protocols: [ "http", "https" ],
  require_tld: false,
  require_protocol: true,
  require_host: true,
  require_valid_protocol: true,
  disallow_auth: true,
  allow_trailing_dot: false,
  allow_protocol_relative_urls: false,
  allow_fragments: false,
  allow_query_components: false,
};

export function IsBaseUrl(
  options: Partial<ValidatorJS.IsURLOptions> = {},
  validationOptions?: ValidationOptions,
): PropertyDecorator {
  return IsUrl(
    {
      ...baseUrlValidation,
      ...options,
    },
    validationOptions,
  );
}
